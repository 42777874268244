import React from 'react';
import {
  MdDashboard,
  MdPayment,
  MdLogout,
  MdViewModule,
  MdManageAccounts,
  MdPaid,
  MdLibraryBooks,
  MdQuestionAnswer,
  MdChat,
  MdAutoFixNormal,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { adminLogout } from '../../store/reducers/authReducer';
import templateConfig from '../config/template.config.json';

const routes = [
  {
    name: 'Dashboard',
    path: '/admin/dashboard',
    icon: <MdDashboard size={20} />,
  },
  {
    name: 'Plans',
    path: '/admin/plans',
    icon: <MdPayment size={20} />,
  },
  {
    name: 'Chapters',
    path: '/admin/chapters',
    icon: <MdViewModule size={20} />,
  },
  {
    name: 'Questions',
    path: '/admin/questions',
    icon: <MdQuestionAnswer size={20} />,
  },
  {
    name: 'General',
    path: '/admin/general',
    icon: <MdAutoFixNormal size={20} />,
  },
  {
    name: 'FAQ',
    path: '/admin/faq',
    icon: <MdChat size={20} />,
  },
  {
    name: 'Users',
    path: '/admin/users',
    icon: <MdManageAccounts size={20} />,
  },
  {
    name: 'Finances',
    path: '/admin/finances',
    icon: <MdPaid size={20} />,
  },
  {
    name: 'Coupons',
    path: '/admin/coupons',
    icon: <MdPaid size={20} />,
  },
  {
    name: 'Book Orders',
    path: '/admin/book-orders',
    icon: <MdLibraryBooks size={20} />,
  },
];

function SideBarSeparator() {
  return <div className="mx-4 my-5 border-b border-white" />;
}

export function AdminSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch(); // eslint-disable-line

  const onLogout = () => {
    // removeCookie('lsToken', { path: '/' });
    // removeCookie('lsRefreshToken', { path: '/' });
    navigate('/admin');
  };

  const hoverSideBar = (locationPathname: string, routePath: string) => {
    if (locationPathname === routePath) return 'bg-primary-500';
    if (locationPathname.includes(routePath)) return 'bg-primary-500';
    return 'hover:bg-primary-500';
  };

  return (
    <aside className="fixed top-0 left-0 flex flex-col bg-primary-600 h-full w-10px sm:w-40 sm:py-10 text-white">
      <div className="pb-10 text-center hidden sm:block">
        <strong>{templateConfig.header.first}</strong>
        {templateConfig.header.second}
      </div>
      <ul className="w-full">
        {routes.map((route) => (
          <li
            key={route.path}
            className={
              hoverSideBar(location.pathname, route.path)
              // location.pathname === route.path
              //   ? 'bg-primary-500'
              //   : 'hover:bg-primary-500'
            }
          >
            <Link
              to={route.path}
              className="px-4 flex w-full items-center h-10"
            >
              {route.icon}
              <span className="pl-3 hidden sm:block">{route.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <SideBarSeparator />
      <button
        type="button"
        className="px-4 flex w-full items-center h-10 hover:bg-primary-500"
        onClick={() => dispatch(adminLogout(onLogout))}
      >
        <MdLogout size={20} />
        <span className="pl-3 hidden sm:block">Logout</span>
      </button>
    </aside>
  );
}
