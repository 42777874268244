/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { CouponModel } from '../../../../store/reducers/couponsReducer';
import { maskToUSCurrency } from '../../../utils/currency-formater';

export type CouponsTableColumns = {
  onEdit: (coupon: CouponModel) => void;
  onDelete: (id: number) => void;
};

export function makeCouponsTableColumns({ onEdit, onDelete }: CouponsTableColumns) {
  return [
    {
      name: 'ID',
      sortable: true,
      selector: (row: any) => row.id,
      center: true,
    },
    {
      name: 'CODE',
      sortable: true,
      wrap: true,
      selector: (row: any) => row.code.toUpperCase(),
      style: { fontWeight: 'bold', textAlign: 'center' },
    },
    {
      name: 'TYPE',
      sortable: true,
      selector: (row: any) => row.discountType.toLowerCase(),
      center: true,
    },
    {
      name: 'DISCOUNT',
      sortable: true,
      selector: (row: any) => row.discount,
      format: (row: any) =>
        row.discountType.toLowerCase() === 'percentage'
          ? `${row.discount}%`
          : maskToUSCurrency(row.discount),
      center: true,
    },
    {
      name: 'EXPIRATION DATE',
      sortable: true,
      selector: (row: any) => (row.expirationDate ? row.expirationDate.split('T')[0] : 'Lifetime'),
      center: true,
    },
    {
      name: 'ACTIVE',
      sortable: true,
      center: true,
      selector: (row: any) => (row.isActive ? 'Active' : 'Inactive'),
    },
    {
      name: 'ACTIONS',
      wrap: true,
      selector: (row: any) => row.action,
      center: true,
      cell: (row: any) => (
        <div className="flex justify-end w-full space-x-2">
          <button
            className="flex-1 py-2 px-3 rounded-md bg-btnEdit hover:bg-btnEditOnHover text-xs text-white font-bold"
            onClick={() =>
              onEdit({
                id: row.id,
                code: row.code,
                discount: row.discount,
                discountType: row.discountType,
                expirationDate: row.expirationDate || 'Lifetime',
                isActive: row.isActive
              })
            }
          >
            Edit
          </button>
          <button
            className="flex-1 py-2 px-3 rounded-md bg-btnDelete hover:bg-btnDeleteOnHover text-xs text-white font-bold"
            onClick={() => onDelete(row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
}
