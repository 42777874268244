import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../../app/config/api';
import { AppDispatch } from '../../types';
import { ErrorMessage } from '../../../app/constants/error_messages';
import { InitialState, StateStatus } from '../../helpers/initialState';
import { CouponModel } from '../couponsReducer';

export type CouponFilter = {
  sortBy?: string;
  orderBy?: string;
  take?: number;
  page?: number;
  code?: string;
  expirationDate?: string;
};

export type CouponTableModel = {
  id: number;
  code: string;
  discount: number;
  expirationDate: string;
};

export type Pagination = {
  page: number;
  perPage: number;
  totalPages: number;
  totalItems: number;
};

export type CouponsTable = {
  pagination: Pagination;
  data: CouponTableModel[];
};

const initialState: InitialState<CouponsTable> = {
  status: StateStatus.INITIAL,
  data: {
    data: [],
    pagination: {
      page: 0,
      perPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  errorMessage: '',
};

const slice = createSlice({
  name: 'couponsTable',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllCouponsTable =
  ({ sortBy, orderBy, page, take = 10, code, expirationDate }: CouponFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const codeString = code ? `code=${code}` : '';
      const expirationDateString = expirationDate ? `expiration_date=${expirationDate}` : '';
      const sortByString = sortBy ? `sortBy=${sortBy}` : '';
      const orderByString = orderBy ? `orderBy=${orderBy}` : '';

      const response = await api.get(
        `/coupons/admin/table?take=${take}&page=${page}&${codeString}&${expirationDateString}&${sortByString}&${orderByString}`,
      );

      const responseData: any = response.data.body.data;

      const couponsTable: CouponsTable = {
        pagination: {
          page: responseData.pagination.page,
          perPage: responseData.pagination.per_page,
          totalItems: responseData.pagination.total_items,
          totalPages: responseData.pagination.total_pages,
        },
        data:
          responseData.data.map((data: any) => ({
            id: data.id,
            code: data.code,
            discount: data.discount,
            expirationDate: data.expiration_date,
          })) ?? [],
      };

      dispatch(onSuccess());
      dispatch(setData(couponsTable));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
