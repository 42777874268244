/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { FormInputLoadingButton } from '../../../components/Button';
import { createCoupon, CouponModel, updateCoupon } from '../../../../store/reducers/couponsReducer';
import { showErrorToast, showSuccessToast } from '../../../components/toast/ShowToast';
import { maskToUSCurrency, removeMaskedMoney } from '../../../utils/currency-formater';

const { Input, Select, Option, Switch } = MaterialTailwind as any;

export function CouponsForm() {
  const [id, setId] = useState<number>();
  const [code, setCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState<'PERCENTAGE' | 'FIXED'>('PERCENTAGE');
  const [isActive, setIsActive] = useState(true);
  const [expirationDate, setExpirationDate] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const dispatch: any = useDispatch();

  const today = new Date().toISOString().split('T')[0];
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const maxDateString = maxDate.toISOString().split('T')[0]; // Data máxima 5 anos à frente no formato yyyy-MM-dd

  useEffect(() => {
    if (location.state) {
      const coupon = location.state as CouponModel;
      setId(coupon.id);
      setCode(coupon.code);
      setDiscount(coupon.discount);
      setDiscountType(coupon.discountType);
      setExpirationDate(coupon.expirationDate || '');
      setIsActive(coupon.isActive ?? true);
    }
  }, [location]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const payload = { code, discount, discountType, expirationDate, isActive };
    console.log("Enviando para updateCoupon:", id, payload);

    if (action === 'create') {
      await dispatch(
        createCoupon(
          payload,
          showSuccessToast,
          showErrorToast
        )
      );
    } else {
      await dispatch(
        updateCoupon(
          id!,
          payload,
          showSuccessToast,
          showErrorToast
        )
      );
    }

    navigate(-1)
    setLoading(false);
  };

  return (
    <AnimatedPage>
      <form onSubmit={onSubmit} className="max-w-2xl ml-0">
        <div className="flex items-center mb-6">
          <Link to="/admin/coupons" className="text-primary-500 hover:text-primary-700">
            <IoArrowBackSharp size={30} />
          </Link>
          <h2 className="ml-8">
            {action === 'create' ? 'Create a new coupon' : 'Edit your coupon'}
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Input label="Code" color="teal" size="lg" variant="standard" required value={code} onChange={(e: any) => setCode(e.target.value.toUpperCase())} />
          </div>
          <div>
            <div className="flex w-full">
              {discountType === "FIXED" ?
                <Input
                  label="Price"
                  color="teal"
                  type="text"
                  variant="standard"
                  value={maskToUSCurrency(discount)}
                  required
                  size="lg"
                  className="border-primary-500 hover:border-primary-600 w-full"
                  onChange={(e: any) => {
                    const value = removeMaskedMoney(e.target.value);
                    if (value >= 0 && value <= 100) {
                      setDiscount(value);
                    }
                  }}
                />
                :
                <Input label="Discount" color="teal" size="lg" variant="standard" type="number" required value={discount} onChange={(e: any) => {
                  const value = Number(e.target.value);
                  if (value >= 0 && value <= 100) {
                    setDiscount(value);
                  }
                }
                } />
              }
              <div className="flex items-center justify-center px-4 bg-gray-300 border-b border-gray-400 text-gray-600">
                {discountType === "FIXED" ? "USD" : "%"}
              </div>
            </div>
          </div>
          <div>
            <Select label="Discount Type *" color="teal" size="lg" required value={discountType} onChange={(value: any) => setDiscountType(value)}>
              <Option value="PERCENTAGE">Percentage</Option>
              <Option value="FIXED">Fixed</Option>
            </Select>
          </div>
          <div>
            <Input
              lang="en"
              label="Expiration Date"
              color="teal"
              size="lg"
              variant="standard"
              type="date"
              value={expirationDate.split('T')[0]}
              onChange={(e: any) => {
                const formattedDate = new Date(e.target.value).toISOString().split('T')[0];
                setExpirationDate(formattedDate);
              }}
              min={today}
              max={maxDateString}
            />
          </div>
          <div className="flex items-center gap-4 mt-4">
            <Switch color="teal" checked={isActive} onChange={(e: any) => setIsActive(e.target.checked)} />
            <span>{isActive ? "Active" : "Inactive"}</span>
          </div>
        </div>
        <div className="mt-8">
          <FormInputLoadingButton title={action === 'create' ? 'Create' : 'Edit'} titleWhenLoading={action === 'create' ? 'Creating' : 'Editing'} isLoading={loading} />
        </div>
      </form>
    </AnimatedPage>
  );
}
