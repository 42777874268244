/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { AxiosError } from 'axios';
import { api } from '../../app/config/api';
import { AppDispatch } from '../types';
import { ResponseList } from '../helpers/axiosResponse';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';

const normalizedCouponsData = (
  data: CouponModel[],
) =>
  data.map((coupon: CouponModel) => ({
    id: coupon.id,
    code: coupon.code,
    discount: coupon.discount,
    discountType: coupon.discountType,
    expirationDate: coupon.expirationDate,
  }));

export type CouponModel = {
  id: number;
  code: string;
  discount: number;
  discountType: 'PERCENTAGE' | 'FIXED';
  expirationDate: string | null;
  isActive: boolean;
};

type state = InitialState<CouponModel[]> & {
  normalizedData: any[];
};

const initialState: state = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
  normalizedData: [],
};

const slice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDataNormalized: (state, action) => {
      state.normalizedData = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, setDataNormalized, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

type CouponBody = {
  code: string;
  discount: number;
  discountType: string;
  expirationDate: string;
};

export const createCoupon =
  (
    body: CouponBody,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void
  ) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const payload: any = {
        ...body,
        code: body.code.toUpperCase(),
        discountValue: body.discount,
        isActive: true,
        usageLimit: null,
      };
      delete payload.discount;

      if (body.expirationDate) {
        const formattedDate = new Date(body.expirationDate).toISOString().split('T')[0];
        payload.expirationDate = formattedDate;
      } else {
        payload.expirationDate = null;
      }

      await api.post<ResponseList>('/coupons', payload);
      dispatch(onSuccess());
      if (handleSuccess)
        handleSuccess('Coupon has been successfully created.');
      dispatch(findAllCoupons());
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.body?.errors === 'Already exists a coupon with this code' && handleError) {
          handleError('Coupon code already exists.');
          return
        }
        if (handleError) {
          handleError(ErrorMessage.UNEXPECTED);
          return
        }
      }

      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const findAllCoupons = () => async (dispatch: AppDispatch) => {
  dispatch(onLoading());

  try {
    const response = await api.get<ResponseList>('/coupons');

    const responseData = response.data.body?.data;

    if (!Array.isArray(responseData)) {
      throw new Error('API response is not an array');
    }

    const coupons: CouponModel[] = responseData.map((data) => ({
      id: data.id,
      code: data.code,
      discount: parseFloat(data.discountValue),
      discountType: data.discountType,
      expirationDate: data.expirationDate || 'Lifetime',
      isActive: data.isActive
    }));

    dispatch(setData(coupons));
    dispatch(setDataNormalized(normalizedCouponsData(coupons)));
    dispatch(onSuccess());
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const updateCoupon = (id: number, body: CouponBody,
  handleSuccess?: (message: string) => void,
  handleError?: (message: string) => void
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());

    const payload: any = {
      ...body,
      discountValue: body.discount,
    };
    delete payload.discount;

    if (body.expirationDate && body.expirationDate !== 'Lifetime') {
      payload.expirationDate = new Date(body.expirationDate).toISOString();
    } else {
      delete payload.expirationDate;
    }

    await api.patch<ResponseList>(`/coupons/${id}`, payload);

    dispatch(onSuccess());
    if (handleSuccess)
      handleSuccess('Coupon has been successfully updated.');
    dispatch(findAllCoupons());
  } catch (error) {
    if (handleError) {
      handleError(ErrorMessage.UNEXPECTED);
    }
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const deleteCoupon = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    await api.delete<ResponseList>(`/coupons/${id}`);
    dispatch(findAllCoupons());
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};