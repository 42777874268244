import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { RootState } from '../../store/types';
// import { Counter } from '../components/Counter';
import { Login } from '../pages/admin/Login';
import { Plans } from '../pages/admin/plans';
import { PlansForm } from '../pages/admin/plans/PlansForm';
import { PageNotFound } from '../pages/PageNotFound';
import { ChaptersForm } from '../pages/admin/chapters/ChaptersForm';
import { Questions } from '../pages/admin/questions';
import { QuestionsForm } from '../pages/admin/questions/QuestionsForm';
import { Finances } from '../pages/admin/finances';
import { Coupons } from '../pages/admin/coupons';
import { CouponsForm } from '../pages/admin/coupons/CouponsForm';
import { BookOrders } from '../pages/admin/book-orders';
import { Users } from '../pages/admin/users';
import { FaqForm } from '../pages/admin/faq/FaqForm';
import { Faq } from '../pages/admin/faq';
import { Chapters } from '../pages/admin/chapters';
import { Dashboard } from '../pages/admin/dashboard';
import { validateToken } from '../../store/reducers/authReducer';
import { CategoryForm } from '../pages/admin/faq/CategoryForm';
import { General } from '../pages/admin/general';

export const AdminRouter = () => {
  const location = useLocation();
  const [authLoading, setAuthLoading] = useState(true);

  const cookies = new Cookies();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: any = useDispatch();

  const { token, isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (token) {
      dispatch(validateToken(token));
    } else {
      const localToken = cookies.get('lsToken');
      if (localToken) dispatch(validateToken(localToken));
    }

    setAuthLoading(false);
  }, []);

  if (authLoading) return <div>Loading......</div>;

  if (!(token && isLoggedIn)) return <Login />;

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to="/admin/dashboard" />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/users" element={<Users />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/general" element={<General />} />
      <Route path="/plans/form/:action" element={<PlansForm />} />
      <Route path="/plans/form/:action/:planId" element={<PlansForm />} />
      {/* <Route path="/templates" element={<Templates />} /> */}
      {/* <Route path="/templates/form" element={<TemplateForm />} /> */}
      <Route path="/chapters" element={<Chapters />} />
      <Route path="/chapters/form/:action" element={<ChaptersForm />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/faq/form/:action" element={<FaqForm />} />
      <Route path="/faq/form/:action/:planId" element={<FaqForm />} />
      <Route path="/category" element={<CategoryForm />} />
      <Route path="/category/form/:action" element={<CategoryForm />} />
      <Route path="/category/form/:action/:planId" element={<CategoryForm />} />
      <Route
        path="/chapters/form/:action/:chapterId"
        element={<ChaptersForm />}
      />
      <Route path="/questions" element={<Questions />} />
      <Route path="/questions/form/:action" element={<QuestionsForm />} />
      <Route
        path="/questions/form/:action/:questionId"
        element={<QuestionsForm />}
      />
      <Route path="/finances" element={<Finances />} />
      <Route path="/coupons" element={<Coupons />} />
      <Route path="/coupons/form/:action" element={<CouponsForm />} />
      <Route path="/coupons/form/:action/:planId" element={<CouponsForm />} />
      <Route path="/book-orders" element={<BookOrders />} />
      {/* <Route path="counter" element={<Counter />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
