/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { AxiosError } from 'axios';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { api } from '../../app/config/api';

import { AppDispatch } from '../types';

const cookies = new Cookies();

export interface IAuthState {
  loading: boolean;
  token: string | null;
  isLoggedIn: boolean;
}
export interface ICookies {
  name?: string;
  value?: any;
  options?: any;
}

export interface IResponseLogin {
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    accountRole: number;
  };
  access_token: string;
  refresh_token: string;
}

export const initialState: IAuthState = {
  loading: false,
  token: cookies.get('lsToken'),
  // token: localStorage.getItem('token'),
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.token = action.payload; // colocar no localstorage
    },
    // setAdmin: (state, action: PayloadAction<boolean>) => {
    //   state.isAdmin = action.payload;
    // },
    loginSuccess: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      // state.isAdmin = false;
      state.isLoggedIn = false;
      state.token = null; // apagar do localstorage
    },
  },
});

export const { setToken, loginSuccess, startLoading, stopLoading, logout } =
  authSlice.actions;

export default authSlice.reducer;

export const adminLogin =
  (
    email: string,
    password: string,
    handleSuccess?: () => void,
    handleError?: () => void,
  ) =>
    async (dispatch: AppDispatch) => {
      dispatch(startLoading());

      const body = { email, password, signUpType: 'user' };

      try {
        const result = await api.post('/auth/login-adm', body);

        const response: IResponseLogin = result.data?.body?.data;

        if (!response.access_token) throw new Error(result.data?.body?.message);

        // set token in localStorage
        // localStorage.setItem('token', response.access_token);
        // localStorage.setItem('refreshToken', response.refresh_token);

        // set token in cookies
        cookies.set('lsToken', response.access_token, { path: '/' });
        cookies.set('lsRefreshToken', response.refresh_token, { path: '/' });

        const token = cookies.get('lsToken');

        api.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
        dispatch(setToken(token));
        dispatch(loginSuccess(true));

        if (handleSuccess) handleSuccess();
      } catch (error) {
        // error handling goes down here...
        dispatch(loginSuccess(false));
        if (handleError) handleError();
        // if (error instanceof AxiosError) console.error(error.message);
      } finally {
        setTimeout(() => dispatch(stopLoading()), 1000);
      }
    };

export const adminLogout =
  (handleSuccess?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    dispatch(logout());

    cookies.remove('lsToken', { path: '/' });
    cookies.remove('lsRefreshToken', { path: '/' });

    // localStorage.removeItem('token');
    // localStorage.removeItem('refreshToken');

    if (handleSuccess) handleSuccess();
    setTimeout(() => dispatch(stopLoading()), 1000);
  };

export const handleRefreshToken = () => async (dispatch: AppDispatch) => {
  const body = {
    refreshToken: `Bearer ${cookies.get('lsRefreshToken')}`,
  };
  const response = await api.post('/auth/refresh-token', body);

  if (response.data.status !== 200) {
    dispatch(adminLogout());
  }

  const newToken = response.data.body.data.access_token;
  const newRefreshToken = response.data.body.data.refresh_token;

  cookies.remove('lsToken', { path: '/' });
  cookies.remove('lsRefreshToken', { path: '/' });

  cookies.set('lsToken', newToken, {
    maxAge: 60 * 60 * 24 * 30,
    path: '/',
  });

  cookies.set('lsRefreshToken', newRefreshToken, {
    maxAge: 60 * 60 * 24 * 30,
    path: '/',
  });

  dispatch(loginSuccess(true));
  dispatch(setToken(newToken));
};

export const validateToken =
  (token: string) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    const refreshToken = cookies.get('lsRefreshToken');
    try {
      const response = await api.post('/auth/valid-token', {
        token,
        refreshToken,
      });

      const newToken = response.data.body.data.access_token ?? null;
      const newRefreshToken = response.data.body.data.refresh_token ?? null;

      if (newToken && refreshToken) {
        cookies.set('lsToken', newToken, {
          maxAge: 60 * 60 * 24 * 30,
          path: '/',
        });

        cookies.set('lsRefreshToken', newRefreshToken, {
          maxAge: 60 * 60 * 24 * 30,
          path: '/',
        });

        dispatch(setToken(newToken));
        dispatch(loginSuccess(true));
        api.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      } else {
        dispatch(setToken(token));
        dispatch(loginSuccess(true));
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
    } catch (error: any) {
      // ------------------------------
      if (error.response.data.statusCode !== 403) {
        dispatch(adminLogout());
      }
      // else {
      //   dispatch(handleRefreshToken());
      // }
      // ------------------------------
      // error handling goes down here...
      // if (error.response.data.statusCode === 403) {
      // if (error instanceof AxiosError) {
      //   if (error.response?.status === 403) {
      //     // if (response.data.body.status === 'error')
      //     //   if (response.status === 403) {
      //     console.log('error', error.response.data);
      //     dispatch(handleRefreshToken(refreshToken, setCookie, removeCookie));
      //   } else {
      //     console.log('logout');
      //     dispatch(adminLogout(removeCookie));
      //   }
      // }
    } finally {
      dispatch(stopLoading());
    }
  };

export const validAndRefreshToken =
  (bodyAuth: object) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response: any = await api.get('/auth/valid-token', bodyAuth);

      cookies.set('lsToken', response.access_token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });

      cookies.set('lsRefreshToken', response.refresh_token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });

      dispatch(setToken(response.access_token));
      dispatch(loginSuccess(true));
      api.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
    } catch (error: any) {
      // ------------------------------
      // if (error.response.data.statusCode !== 403) {
      dispatch(adminLogout());
      // } else {
      //   dispatch(handleRefreshToken());
      // }
      // ------------------------------
      // error handling goes down here...
      // if (error.response.data.statusCode === 403) {
      // if (error instanceof AxiosError) {
      //   if (error.response?.status === 403) {
      //     // if (response.data.body.status === 'error')
      //     //   if (response.status === 403) {
      //     console.log('error', error.response.data);
      //     dispatch(handleRefreshToken(refreshToken, setCookie, removeCookie));
      //   } else {
      //     console.log('logout');
      //     dispatch(adminLogout(removeCookie));
      //   }
      // }
    } finally {
      dispatch(stopLoading());
    }
  };

export const checkToken = (token: string) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    dispatch(setToken(token));
    dispatch(loginSuccess(true));
  } else {
    dispatch(adminLogout());
  }
};

axios.interceptors.response.use(
  (response) => response,
  async (error: any) => {
    if (error.response.status === 401 || error.response.status === 403) {
      // const refreshToken = localStorage.getItem('refreshToken');
      // const token = localStorage.getItem('token');
      const refreshToken = cookies.get('lsRefreshToken');
      const token = cookies.get('lsToken');

      const bodyAuth = {
        refreshToken,
        token,
      };
      const response = await api.post('/auth/valid-token', bodyAuth);
      console.log('omg error', response);
    }
  },
);
