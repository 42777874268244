import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import * as MaterialTailwind from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';
import { Modal } from '../../../components/Modal';
import { RootState } from '../../../../store/types';
import { LinkButton } from '../../../components/Button';
import { makeCouponsTableColumns } from './CouponsTableColumns';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { StateStatus } from '../../../../store/helpers/initialState';
import {
  deleteCoupon,
  findAllCoupons,
  CouponModel,
} from '../../../../store/reducers/couponsReducer';

const { Chip } = MaterialTailwind as any;

export function Coupons() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [removeItemID, setRemoveItemID] = useState<number>();

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.coupons);
  const coupons = useSelector((state: RootState) => state.coupons.data);
  const normalizedCoupons = useSelector(
    (state: RootState) => state.coupons.normalizedData,
  );

  useEffect(() => {
    dispatch(findAllCoupons());
  }, []);

  const onRemoveItem = () => {
    if (removeItemID !== undefined) dispatch(deleteCoupon(removeItemID));
    setModalOpen(false);
  };

  return (
    <AnimatedPage>
      <>
        <div className="border rounded-md">
          <Modal
            isOpen={isModalOpen}
            setOpen={setModalOpen}
            onDone={onRemoveItem}
            title="Are you sure you want to delete it?"
          >
            <p className="pt-4">The data will be permanently deleted.</p>
            <p>This action is irreversible.</p>
          </Modal>
        </div>
        <DataTable
          title={
            <>
              <div className="h-4" />
              <p>Coupons</p>
              {state.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={normalizedCoupons}
                  filename={`coupons-${new Date().toISOString()}.csv`}
                  separator=";"
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          columns={makeCouponsTableColumns({
            onEdit: (coupon: CouponModel) =>
              navigate(`/admin/coupons/form/edit/${coupon.id}`, {
                state: coupon,
              }),
            onDelete: (id) => {
              setRemoveItemID(id);
              setModalOpen(true);
            },
          })}
          data={coupons}
          pagination
          responsive
          highlightOnHover
          progressPending={state.status === StateStatus.LOADING}
          striped
          actions={
            <LinkButton text="Create coupon" route="/admin/coupons/form/create" />
          }
          customStyles={customStyles}
        />
      </>
    </AnimatedPage>
  );
}
